import { ReactElement } from 'react';
import { CredentialEnum, EventEntity } from '../../client/graphql';
import { PLATECredentialMini } from '../../composition/credential/plate.credential';
import { QRCODECredentialMini } from '../../composition/credential/qrcode.credential';
import { RFIDCredentialMini } from '../../composition/credential/rfid.credential';
import { TCSCredentialMini } from '../../composition/credential/tcs.credential';
import { EnteredSessionTimeline } from '../../composition/session-timeline/ENTERED.session-timeline';
import { EntryAuthorizedSessionTimeline } from '../../composition/session-timeline/ENTRY-AUTHORIZED.session-timeline';
import { EntryDeniedSessionTimeline } from '../../composition/session-timeline/ENTRY-DENIED.session-timeline';
import { ExitAuthorizedSessionTimeline } from '../../composition/session-timeline/EXIT-AUTHORIZED.session-timeline';
import { ExitDeniedSessionTimeline } from '../../composition/session-timeline/EXIT-DENIED.session-timeline';
import { ExitedSessionTimeline } from '../../composition/session-timeline/EXITED.session-timeline';
import { PaymentNotifiedSessionTimeline } from '../../composition/session-timeline/PAYMENT-NOTIFIED.session-timeline';
import { PedestrianAccessSessionTimeline } from '../../composition/session-timeline/PEDESTRIAN-ACCESS.session-timeline';
import { SKIDATACredentialMini } from '../../composition/credential/skidata.credential';

//! Faudra songer un jour à remplacer cette merde en back
const CredentialComponentSelector = (
  credentials: EventEntity['credentials'],
) => {
  if (!credentials) return '';

  const credentialClear = credentials.filter((e) => e !== null);

  if (credentialClear.length === 0) return '';

  const { type, provider, value, description } = credentialClear[0]!;

  const credentialsMap = [
    {
      type: 'PROVIDER_EXTERNAL_ID',
      provider: 'OSP',
      component: <RFIDCredentialMini data={{ value: description }} />,
    },
    {
      type: null,
      provider: 'TCSOSP',
      component: <TCSCredentialMini data={{ value: value }} />,
    },
    {
      type: null,
      provider: 'TCS',
      component: <TCSCredentialMini data={{ value: value }} />,
    },
    {
      type: null,
      provider: 'SKIDATA',
      component: <SKIDATACredentialMini data={{ value: value }} />,
    },
    {
      type: 'RFID',
      provider: null,
      component: <RFIDCredentialMini data={{ value: description }} />,
    },
    {
      type: 'QRCODE',
      provider: null,
      component: <QRCODECredentialMini data={{ value: '' }} />,
    },
    {
      type: 'PLATE',
      provider: null,
      component: <PLATECredentialMini data={{ value: value }} />,
    },
  ] as const satisfies {
    type: string | null;
    provider: string | null;
    component: ReactElement;
  }[];

  return (
    credentialsMap.find(
      (e) =>
        (e.type === null || e.type === type) &&
        (e.provider === null || e.provider === provider),
    )?.component || <></>
  );
};

export const ComponentInfosSelector = ({
  state,
  createdAt,
  credentials,
  accessPointCode,
  reason,
  amount,
  currency,
}: EventEntity & {
  accessPointCode?: string | null;
  currency?: string | null;
  createdAt: string;
}) => {
  const mapedStateToComponent: { [key: string]: ReactElement } = {
    ENTRY_AUTHORIZED: (
      <EntryAuthorizedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    EXIT_AUTHORIZED: (
      <ExitAuthorizedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    EXITED: (
      <ExitedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
        }}
      />
    ),
    ENTRY_DENIED: (
      <EntryDeniedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
          subtitle: reason || 'undefined',
        }}
      />
    ),
    ENTERED: (
      <EnteredSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
        }}
      />
    ),
    EXIT_DENIED: (
      <ExitDeniedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
          subtitle: reason || 'undefined',
        }}
      />
    ),
    AUTHORIZE_PEDESTRIAN: (
      <PedestrianAccessSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra: CredentialComponentSelector(credentials),
        }}
      />
    ),
    PAYMENT_NOTIFIED: (
      <PaymentNotifiedSessionTimeline
        key={crypto.randomUUID()}
        data={{
          date: createdAt,
          device: accessPointCode || '-',
          extra:
            amount && currency
              ? new Intl.NumberFormat(currency, {
                  style: 'currency',
                  currency: currency,
                }).format(amount / 100)
              : currency
                ? new Intl.NumberFormat(currency, {
                    style: 'currency',
                    currency: currency,
                  }).format(0)
                : '-',
        }}
      />
    ),
  };
  return mapedStateToComponent[state];
};
